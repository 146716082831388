const queryForm = function (settings) {
  const reset = settings && settings.reset ? settings.reset : false
  const self = window.location.toString()
  const querystring = self.split('?')
  if (querystring.length > 1) {
    const pairs = querystring[1].split('&')
    for (const i in pairs) {
      const keyval = pairs[i].split('=')
      if (reset || window.sessionStorage.getItem(keyval[0]) === null) {
        window.sessionStorage.setItem(keyval[0], decodeURIComponent(keyval[1]))
      }
    }
  }
  const hiddenFields = document.querySelectorAll('input[type=hidden], input[type=text]')
  for (let i = 0; i < hiddenFields.length; i++) {
    const param = window.sessionStorage.getItem(hiddenFields[i].name)
    if (param) document.getElementsByName(hiddenFields[i].name)[0].value = param
  }
}

const cookie = document.cookie.split(';').find((x) => x.match(/remote_addr/))

if (cookie) {
  const remoteAddr = cookie.split('=')[1]
  window.sessionStorage.setItem('remote_addr', remoteAddr)
}

setTimeout(function () { queryForm() }, 1000)
